<template>
	<el-select v-model="values" :placeholder="pidChoose ? '请先选择数据分类' : '请选择'" :filterable="filterable" @change="change">
		<div style="flex-direction: row;display: flex;padding:0 5px 5px 5px;" v-if="canAdd && data && data.akey">
			<el-input placeholder="请输入内容" v-model="name" maxlength="30" show-word-limit></el-input>
			<add-button @click.native="ajaxAdd" label="添加" style="margin-left:5px;"></add-button>
		</div>
		<el-option
			v-for="item in list"
			:key="item.id"
			:label="item.name"
			:value="item.id"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "TypeSelect",
	data(){
		return{
			list:[],
			values:'',
			name:'',
		}
	},
	model:{
		prop:'value',
		event:'change'
	},
	props:{
		value:{
			type:[String,Number],
			default:''
		},
		data:{
			type:[Array,Object],
			default(){
				return null
			}
		},
		url:{
			type:String,
			default:''
		},
		options:{
			type:[Array,Object],
			default(){
				return null
			}
		},
		canAdd:{
			type:Boolean,
			default:true
		},
		pidChoose:{
			type:Boolean,
			default:false
		},
		pid:{
			type:[String,Number],
			default:''
		},
		pidKey:{
			type:String,
			default:''
		},
		filterable:{
			type:Boolean,
			default:false
		}
	},
	created() {
		this.values = this.value;
	},
	watch:{
		value(n,o){
			this.values = n;
		},
		pid(n,o){
			console.log(n,o);
			this.getList();

			if(n !== o && o !== '') {
				this.values = '';
				this.$emit("change", '');
			}
		}
	},
	mounted() {
		if(this.options) {
			this.list = this.options;
		}else{
			if(!this.pidChoose) {
				this.getList();
			}else{
				if(this.pid){
					this.getList();
				}
			}
		}
	},
	methods:{
		getList() {
			let _this = this;

			this.$http.httpPost({
				url: '/Type/listAll',
				datas: {...this.data,orderBy:'sort',sortBy:'asc',[this.pidKey]:this.pid},
				success(res) {
					_this.list = res;
				}
			});
		},
		change(e){
			this.$emit("change", e);
		},
		ajaxAdd(){
			let _this = this;

			this.$http.httpPost({
				url: '/Type/add',
				datas: {name:this.name,akey:this.data.akey},
				success(res) {
					console.log(res);
					_this.getList();
					_this.name = '';
				}
			});
		}
	}
}
</script>
