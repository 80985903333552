<template>
    <div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
    name: "BarEcharts",
    data(){
        return{
            id:'echarts',
            option:{
                title:{
                    text:'',
                    left:'center',
                    show:false,
                    textStyle:{
                        fontWeight:'normal',
                        fontSize:18
                    }
                },
                tooltip: {
                    // formatter: '{a}:{c}',
                    trigger: 'axis',
                    // formatter: function (params) {
                    //     var relVal = params[0].name;
                    //     for (var i = 0, l = params.length; i < l; i++) {
                    //         relVal += '<div style="display:flex;flex-direction: row;align-items: center;justify-content: space-between;"><span>' + params[i].marker + params[i].seriesName + ' : </span><span>' + params[i].value + '</span></div>'
                    //     }
                    //     return relVal;
                    // },
                    axisPointer: {
                        type: 'shadow',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    textStyle:{
                        align:'left'
                    }
                },
                grid:{
                    top:20,
                    bottom:30,
                    left:10,
                    right:10,
                    containLabel: true
                },
                legend: null,
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel:{
                            rotate:0,
                            overflow:'break',
                            interval:0,
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            show:true
                        },
                        splitLine:{
                            show:false
                        }
                    },
                    {
                        type: 'value',
                        // min: -30,
                        // max: 100,
                        axisLabel: {
                            formatter: '{value} %',
                            show:true
                        },
                        splitLine:{
                            show:false
                        }
                    }
                ],
                series: []
            }
        }
    },
    props:{
        width:String,
        height:String,
        title:String,
        showLegend:{
            type:Boolean,
            default:true
        },
        legendOrient:{
            type:String,
            default:'horizontal'
        },
        titlePos:{
            type:String,
            default:'top'
        },
        titleSize:{
            type:Number,
            default:18
        },
        showTitle:{
            type:Boolean,
            default:true
        },
        barWidth:{
            type:Number,
            default:40
        },
        showLeft:{
            type:Boolean,
            default:true
        },
        showRight:{
            type:Boolean,
            default:true
        },
        data:{
            type:Array,
            default(){
                return []
            }
        },
        legendPos:{
            type:String,
            default:'bottom'
        },
        xAxis:{
            type:Array,
            default(){
                return []
            }
        },
        gridBottom:{
            type:Number,
            default:30
        },
        xNameRotate:{
            type:Number,
            default:0
        }
    },
    created() {
        this.id = this.$utils.randomWords(8);

        // if(this.xAxis.length > 0){
        //     this.option.xAxis[0].data = this.xAxis;
        // }

        // this.barWidth = this.$isMobile ? 20 : 40;
        // this.showLeft = this.$isMobile ? false : this.showLeft;
        // this.showRight = this.$isMobile ? false : this.showRight;

        this.init();
    },
    mounted() {
        this.echarts = this.$echarts.init(document.getElementById(this.id));
        this.echarts.setOption(this.option,true);
        this.initData(this.data,this.xAxis);
    },
    methods:{
        init(){
            if(this.titlePos === 'top'){
                this.option.title.top = 0;
            }else if(this.titlePos === 'bottom'){
                this.option.title.bottom = 0;
            }

            this.option.title.textStyle.fontSize = this.titleSize;

            if(this.title) {
                this.option.title.text = this.title;
                this.option.grid.top = 40;
            }

            this.option.grid.bottom = this.gridBottom;

            this.option.title.show = this.showTitle;
            this.option.yAxis[0].axisLabel.show = this.$isMobile ? false : this.showLeft;
            this.option.yAxis[1].axisLabel.show = this.$isMobile ? false : this.showRight;

            this.option.xAxis[0].axisLabel.rotate = this.xNameRotate;
        },
        initData(data,xaxis){
            // console.log(data);
            // console.log(xaxis);
            this.option.series = [];

            if(xaxis.length > 0){
                this.option.xAxis[0].data = xaxis;
            }

            if(data.length > 0){
                //自动倾斜x轴文字，设置了xNameRotate后无效，默认值45度
                if(data[0].data.length > 5){
                    if(this.xNameRotate){
                        this.option.xAxis[0].axisLabel.rotate = this.xNameRotate;
                    }else{
                        this.option.xAxis[0].axisLabel.rotate = 45;
                    }
                }else{
                    this.option.xAxis[0].axisLabel.rotate = this.xNameRotate;
                }

                let _legend = [];
                for (let i = 0;i < data.length;i++){
                    let _data = data[i];

                    _legend.push(_data.name);
                    if(_data.type === 'bar'){
                        this.option.series.push(this.$echartsJs.getBar(_data.data, _data.name,_data.color,(this.$isMobile ? 20 : this.barWidth),_data.pos ? _data.pos : 'top',_data.options ? _data.options : {}));
                    }else if(_data.type === 'line'){
                        if(_data.color === 'purple') {
                            this.option.series.push(this.$echartsJs.getPurpleLine(_data.data,_data.name));
                        }else if(_data.color === 'red'){
                            this.option.series.push(this.$echartsJs.getRedLine(_data.data,_data.name));
                        }
                    }
                }

                this.option.tooltip.formatter = (params)=>{
                    let relVal = params[0].name;
                    for (let i = 0, l = params.length; i < l; i++) {
                        relVal += '<div style="display:flex;flex-direction: row;align-items: center;justify-content: space-between;"><span style="flex:1;margin-right:10px;">' + params[i].marker + (params[i].seriesName ? params[i].seriesName : params[i].name) + ' : </span><span style="flex:1;text-align: right;">' + params[i].value + '</span>'+ (data[i] && data[i].dw ? data[i].dw : '') +'</div>'
                    }
                    return relVal;
                }

                if(this.showLegend) {
                    this.option.legend = this.$echartsJs.getLegend(_legend,this.legendPos,this.legendOrient);
                }

                this.echarts.setOption(this.option,true);
            }
            // console.log(this.option);
        },
        run(){
            // console.log(this.option);
            // this.echarts.setOption(this.option);
        }
    },
    watch:{
        title(n,o){
            // console.log(n);
            // this.initData(n);
            this.option.title.text = n;
            this.echarts.setOption(this.option,true);
        }
    }
}
</script>
