<template>
    <lee-page bg-img="/images/bg_head_1.jpg" :title="$isMobile ? '中国冷链委' : '链云数据平台'" :sub-title="$isMobile ? '数据平台' : 'Chain Cloud Data Platform'" :height="400">
<!--					<div class="wrap1100 cl" style="margin-top:-55px;">-->
<!--							<el-row :gutter="10">-->
<!--									<el-col :span="6" v-for="(item,index) in tabList">-->
<!--											<div class="block1 box-shadow" style="height: 111px;" @click="$router.push({path:item.path})">-->
<!--													<div class="cl">-->
<!--															<div class="z img margin-top-5">-->
<!--																	<img :src="'/images/icon/'+item.img+'_off.png'" height="37" class="icon-off"/>-->
<!--																	<img :src="'/images/icon/'+item.img+'_on.png'" height="37" class="icon-on"/>-->
<!--															</div>-->
<!--															<div class="z">-->
<!--																	<div class="f20 title f-lq" style="line-height: 44px;">{{item.title}}</div>-->
<!--&lt;!&ndash;                                    <div class="f14 f-thin sub-title">{{item.subTitle}}</div>&ndash;&gt;-->
<!--															</div>-->
<!--													</div>-->
<!--											</div>-->
<!--									</el-col>-->
<!--							</el-row>-->
<!--					</div>-->
					<div class="wrap1100 bg-light box-shadow padding-20" style="margin-top:-150px;">
							<lee-select v-model="type" style="width: 282px;" :options="searchTypeList"></lee-select>
							<lee-select v-model="searchForm.title_type" style="width: 177px;" class="margin-left" :options="[{label:'标题',value:'title'},{label:'关键字',value:'key'}]"></lee-select>
<!--                <lee-input placeholder="输入标题" v-model="searchForm.title" class="margin-left" style="width: 177px"/>-->
							<lee-input placeholder="输入关键词" v-model="searchForm.title" style="width: 385px" class="margin-left"/>
							<el-button type="success" size="medium" class="vm lee-button margin-left f-lq" @click="toSearch" style="width: 186px;"><i class="el-icon-s-promotion"></i>查询</el-button>
					</div>
<!--					<div class="margin-top-150 bg-img-1">-->
<!--							<div class="text-center f38 dark-3 f-lq divider-green">最新资讯</div>-->
<!--							<div class="text-center f24 f-thin dark-4">Latest Information</div>-->
<!--							<div style="width: 1436px;" class="margin-auto margin-top-30">-->
<!--									<el-row :gutter="20">-->
<!--											<el-col :span="6" v-for="(item,index) in newsList">-->
<!--													<div class="ad-item hand" @click="$router.push({path:'/news/detail/'+item.id})">-->
<!--															<div class="img"><img :src="item.head_img ? (item.head_img.indexOf('http') >= 0 ? item.head_img : $config.assetsUrl + item.head_img) : ''"/></div>-->
<!--															<div class="time f16 dark-4 f-thin">{{item.create_time}}</div>-->
<!--															<div class="title f22 dark-1 text-line-1">{{item.title}}</div>-->
<!--															<div class="des f16 dark-1 f-thin text-line-2" v-html="item.content" style="line-height: 24px;height: 48px;"></div>-->
<!--															<div class="btn margin-top"><el-button type="success" round>详情<i class="el-icon-right el-icon&#45;&#45;right"></i></el-button></div>-->
<!--													</div>-->
<!--											</el-col>-->
<!--									</el-row>-->
<!--							</div>-->
<!--					</div>-->
<!--					<div class="margin-top-100">-->
<!--							<div class="text-center f38 dark-3 f-lq divider-green">最新政策/标准</div>-->
<!--							<div class="text-center f24 f-thin dark-4">Latest Policies / Standards</div>-->
<!--							<div class="margin-top-100 margin-auto" style="width: 1200px;">-->
<!--									<el-row>-->
<!--											<el-col :span="6" v-for="(item,index) in policyTypeList" class="padding-left-right-40">-->
<!--													<div class="zz-item text-center hand" @click="$router.push({path:'/zhengce/list/policy/'+item.id})">-->
<!--															<div class="zz-item-off" style="height: 300px;">-->
<!--																	<div class="cl"></div>-->
<!--																	<div class="img margin-top-40"><img :src="$config.assetsUrl+item.head_img"/></div>-->
<!--																	<div class="title f28 dark-2 f-lq" style="line-height: 70px;">{{item.name}}</div>-->
<!--&lt;!&ndash;                                    <div class="des f14 dark-2 padding-bottom-30">{{item.content}}</div>&ndash;&gt;-->
<!--															</div>-->
<!--															<div class="zz-item-on bg-light box-shadow padding-20" style="height: 300px;">-->
<!--																	<div class="border-w-5 border-green" style="height: 260px;">-->
<!--																			<div class="cl"></div>-->
<!--																			<div class="title f28 dark-2 f-lq" style="line-height: 100px;">{{item.name}}</div>-->
<!--&lt;!&ndash;                                        <div class="des f14 dark-2 padding-bottom-30">{{item.content}}</div>&ndash;&gt;-->
<!--																	</div>-->
<!--															</div>-->
<!--													</div>-->
<!--											</el-col>-->
<!--											<el-col :span="6" v-for="(item,index) in standardTypeList" class="padding-left-right-40">-->
<!--													<div class="zz-item text-center hand" @click="$router.push({path:'/zhengce/list/standard/'+item.id})">-->
<!--															<div class="zz-item-off" style="height: 300px;">-->
<!--																	<div class="cl"></div>-->
<!--																	<div class="img margin-top-40"><img :src="$config.assetsUrl+item.head_img"/></div>-->
<!--																	<div class="title f28 dark-2 f-lq" style="line-height: 70px;">{{item.name}}</div>-->
<!--&lt;!&ndash;                                    <div class="des f14 dark-2 padding-bottom-30">{{item.content}}</div>&ndash;&gt;-->
<!--															</div>-->
<!--															<div class="zz-item-on bg-light box-shadow padding-20" style="height: 300px;">-->
<!--																	<div class="border-w-5 border-green" style="height: 260px;">-->
<!--																			<div class="cl"></div>-->
<!--																			<div class="title f28 dark-2 f-lq" style="line-height: 100px;">{{item.name}}</div>-->
<!--&lt;!&ndash;                                        <div class="des f14 dark-2 padding-bottom-30">{{item.content}}</div>&ndash;&gt;-->
<!--																	</div>-->
<!--															</div>-->
<!--													</div>-->
<!--											</el-col>-->
<!--									</el-row>-->
<!--							</div>-->
<!--					</div>-->
					<div class="margin-top-50 bg-img-2 padding-bottom-400">
							<div class="cl"></div>
							<div class="margin-top-50 text-center f38 dark-1 f-lq divider-green">最新行业数据</div>
							<div class="text-center f24 f-thin dark-3">Latest Data</div>
							<div class="margin-auto margin-top-20" style="width: 1114px;">
									<lee-index-hydata></lee-index-hydata>
							</div>
					</div>
<!--					<div class="bg-light padding-bottom-100">-->
<!--							<div class="cl"></div>-->
<!--							<div class="margin-top-50 text-center f38 dark-2 f-lq divider-green">研究报告</div>-->
<!--							<div class="text-center f24 f-thin dark-3">Research Reports</div>-->
<!--							<div class="margin-auto margin-top-30" style="width: 1300px;">-->
<!--									<lee-swiper type="" :height="510" indicatorPosition="" class="swiper-round">-->
<!--											<el-carousel-item v-for="(item,index) in yanjiuList" :key="'report_'+index">-->
<!--													<div class="rel">-->
<!--															<div class="rel border-radius-5 hide index-swiper-report-l" :style="{backgroundRepeat:'no-repeat',backgroundSize:'auto 100%',backgroundImage:'url(\'/images/bg_cell_'+(item.slug === 'Industryreport' ? '1' : '3')+'.jpg\')'}">-->
<!--																	<div class="index-report-l-bg text-center padding-top-bottom-40">-->
<!--																			<img src="/images/icon/icon_hy_report.png" width="140"/>-->
<!--																			<div class="margin-top-30 f-lq f24 light">{{item.type === 'industry' ? '行业发展报告' : '细分领域报告'}}</div>-->
<!--&lt;!&ndash;                                        <div class="text-line-3 margin-auto light margin-top-50" style="width: 60%;" v-html="item.content"></div>&ndash;&gt;-->
<!--																	</div>-->
<!--															</div>-->
<!--															<div class="bg-green-light border-radius-5 hide padding-20" style="margin-top:-360px;margin-left:100px;height: 450px;">-->
<!--																	<div class="bg-light hide border-radius-5 padding-20 padding-left-70" style="margin-left:250px;height: 410px;">-->
<!--																			<div class="cl padding-bottom-20 border-bottom">-->
<!--																					<span class="inline-block z bg-dark-2 padding-5">-->
<!--																							<img src="/images/icon/icon_40.png" width="14"/>-->
<!--																					</span>-->
<!--																					<div class="y f18" style="width: 560px;">{{item.title}}</div>-->
<!--																			</div>-->
<!--																			<div class="green f18 margin-top-20">简介</div>-->
<!--																			<div class="dark-1 f-thin text-line-3 f14 margin-top" v-html="item.content"></div>-->
<!--																			<div class="green f18 margin-top-30" v-if="item.type === 'industry'">目录</div>-->
<!--																			<div class="dark-1 f-thin text-line-2 f14 margin-top" v-if="item.type === 'industry'" v-html="item.book_list" style="height: 65px;"></div>-->
<!--																			<div class="cl margin-top-20">-->
<!--																					<el-button type="success" class="y text-center border-radius-0" @click="$router.push({path:item.type === 'industry' ? '/industry/detail/'+item.id : '/domain/detail/'+item.id})" style="width: 150px">-->
<!--																							详情-->
<!--																							<i class="el-icon-right el-icon&#45;&#45;right"></i>-->
<!--																					</el-button>-->
<!--																			</div>-->
<!--																	</div>-->
<!--															</div>-->
<!--													</div>-->
<!--											</el-carousel-item>-->
<!--									</lee-swiper>-->
<!--							</div>-->
<!--					</div>-->
<!--					<div class="bg-img-10 padding-bottom-400">-->
<!--							<div class="cl"></div>-->
<!--							<div class="margin-top-50 text-center f38 light f-lq divider-green">问答中心</div>-->
<!--							<div class="text-center f24 f-thin light">Q&A Center</div>-->
<!--							<div class="margin-auto margin-top-60" style="width: 1300px">-->
<!--									<div class="cl">-->
<!--											<div class="z" style="width: 485px;">-->
<!--													<div class="border-radius-5 padding-20" style="background: rgba(0,0,0,.3);border:2px solid #fff;height: 500px;">-->
<!--															<div class="f30 light f-lq">热门问题</div>-->
<!--															<div class="margin-top-30">-->
<!--																	<div class="cl hand margin-bottom-30" style="height: 37px;line-height: 37px;" v-for="(item,index) in qaList" @click="$router.push({path:'/qa'})">-->
<!--																			<img src="/images/icon/icon_q.png" height="37" class="z"/>-->
<!--																			<div class="z text-line-1 margin-left light f20" style="width: 300px;">{{item.title}}？</div>-->
<!--																			<i class="y el-icon-right green f30" style="margin-top:3px;"></i>-->
<!--																	</div>-->
<!--															</div>-->
<!--													</div>-->
<!--											</div>-->
<!--											<div class="cl y bg-img-6" style="width: 773px;background-size: 100% 100%;">-->
<!--													<div class="y" style="width: 290px;">-->
<!--															<div class="padding-50 hand" :class="qaIndex === 0 ? 'bg-green' : 'bg-dark-op'" style="height: 250px;" @click="changeQaTab(0)">-->
<!--																	<div class="text-center">-->
<!--																			<img src="/images/icon/icon_q_3.png" height="80"/>-->
<!--																	</div>-->
<!--																	<div class="f30 light text-center margin-top f-lq">热门问题</div>-->
<!--&lt;!&ndash;                                    <div class="text-center light f14 f-thin margin-top-20">&ndash;&gt;-->
<!--&lt;!&ndash;                                        我是问题描述问问题描述描述问问题描述描述问问题描述&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--															</div>-->
<!--															<div class="padding-50 hand" :class="qaIndex === 1 ? 'bg-green' : 'bg-dark-op'" style="height: 250px;" @click="changeQaTab(1)">-->
<!--																	<div class="text-center">-->
<!--																			<img src="/images/icon/icon_q_2.png" height="80"/>-->
<!--																	</div>-->
<!--																	<div class="f30 light text-center margin-top f-lq">最新问题</div>-->
<!--&lt;!&ndash;                                    <div class="text-center light f14 f-thin margin-top-20">&ndash;&gt;-->
<!--&lt;!&ndash;                                        我是问题描述问问题描述描述问问题描述描述问问题描述&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--															</div>-->
<!--													</div>-->
<!--											</div>-->
<!--									</div>-->
<!--							</div>-->
<!--					</div>-->
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            type:'zhengce',
            searchForm:{
                title_type:'title',
                title:''
            },
            nowYear:'',
            newsList:[],
            searchTypeList:[
                // {
                //     value: 'news',
                //     label: '新闻资讯'
                // },
                {
                    value: 'zhengce',
                    label: '政策标准'
                },
                {
                    value: 'yanjiu',
                    label: '研究报告'
                },
                // {
                //     value: 'Question',
                //     label: '问答中心'
                // }
            ],
            tabList:[
                {
                    img:'icon_24',
                    title:'政策标准',
                    subTitle:'权威信息，精准解读',
                    path:'/zhengce'
                },
                {
                    img:'icon_25',
                    title:'行业数据',
                    subTitle:'权威数据，精准解读',
                    path:'/hydata'
                },
                {
                    img:'icon_26',
                    title:'研究报告',
                    subTitle:'权威报告，精准解读',
                    path:'/yanjiu'
                },
                {
                    img:'icon_27',
                    title:'问答中心',
                    subTitle:'权威报告，精准解读',
                    path:'/qa'
                }
            ],
            standardTypeList:[],
            policyTypeList:[],
            qaList:[],
            yanjiuList:[],

          qaIndex:0,
        }
    },
    created() {
        let _now = new Date();

        this.nowYear = _now.getFullYear();
    },
    mounted() {
        this.getPolicyType();
        this.getStandardType();
        this.getNews();
        this.getQaList();
        this.getReport('industry','chain');
      // this.getReport('industry','agriculture');
      this.getReport('domain','chain');
      // this.getReport('domain','agriculture');
    },
    methods:{
        toSearch(){
            console.log(this.searchForm);
            if(this.type === 'zhengce'){
                this.$router.push({path:'/zhengce/search',query:{...this.searchForm}})
            }else if(this.type === 'yanjiu'){
                this.$router.push({path:'/yanjiu/search',query:{...this.searchForm}})
            }else if(this.type === 'news'){
                this.$router.push({path:'/news',query:{...this.searchForm}})
            }else{
                this.$router.push({path:'/qa',query:{...this.searchForm}})
            }
        },
        getStandardType(){
            let _this = this;
            this.$http.post({
                url: '/Type/listAll',
                data: {akey:'standard',orderBy:'sort',sortBy:'asc',state:1},
                success(res) {
                    console.log(res);
                    _this.standardTypeList = res;
                }
            });
        },
        getPolicyType(){
            let _this = this;
            this.$http.post({
                url: '/Type/listAll',
                data: {akey:'policy',orderBy:'sort',sortBy:'asc',state:1},
                success(res) {
                    console.log(res);
                    _this.policyTypeList = res;
                }
            });
        },
        getNews(){
            let _this = this;
            this.$http.post({
                url: '/News/listPage',
                data: {page:1,pageSize:4},
                success(res) {
                    _this.newsList = res.list;
                }
            });
        },
        getQaList(orderBy = 'answer_count'){
            let _this = this;
            this.$http.post({
                url:'/Question/listPage',
                data:{page:1,orderBy:orderBy,pageSize:6,sortBy:'desc'},
                success(res){
                    if(res.list){
                        _this.qaList = res.list;
                    }
                }
            })
        },
      getReport(type,mm_type){
        let _this = this;
        this.$http.post({
          url:'/Report/listPage',
          data:{page:1,pageSize:2,type:type,mm_type:mm_type,orderBy:'create_time',sortBy:'desc'},
          success(res){
						for(let i=0;i < res.list.length;i++){
							_this.yanjiuList.push(res.list[i]);
						}
          }
        })
      },
      changeQaTab(i){
          this.qaIndex = i;

          let _type = 'answer_count';

          if(i === 0){
            _type = 'answer_count';
          }else{
            _type = 'create_time';
          }
          this.getQaList(_type);
      }
    }
}
</script>
<style scoped>
.block1{background: #fff;cursor: pointer;padding:30px 0;}
.block1:hover{background: #7ED321;}
.block1 .icon-off{display: unset;}
.block1 .icon-on{display: none;}
.block1:hover .icon-off{display: none;}
.block1:hover .icon-on{display: unset;}
.block1:hover .title,.block1:hover .sub-title{color:#fff !important;}
.block1 .img{width:39px;height:39px;margin:0 30px;}
.block1 .title{color:#6C6C6C;}
.block1 .sub-title{color:#9F9F9F;}

.ad-item{}
.ad-item .img{height:213px;width: 344px;}
.ad-item .img img{height:100%;width:100%;}
.ad-item .time{line-height: 30px;}
.ad-item .title{line-height: 40px;}
.ad-item .des{line-height: 24px;}
</style>
