<template>
	<lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="进出口相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'进出口'}]">
		<div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_inout">
				<div class="wrap1100">
<!--					<lee-year-tab :list="yearList"></lee-year-tab>-->
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<div class="green-line">
							<div class="cl f34">
								<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
								<div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">进出口相关数据</div>
								<div class="y">
									<lee-type-select v-model="time_type" :options="[{id:'month',name:'月度'},{id:'quarter',name:'季度'},{id:'half',name:'半年度'},{id:'year',name:'年度'}]" :can-add="false" @change="onChangeTimeType"></lee-type-select>
								</div>
							</div>
						</div>
						<div class="f16 dark-2" v-if="data">
							进出口总体情况如下：
						</div>
						<div class="text-center">
							<lee-bar-echarts
								ref="hebing"
								:grid-bottom="$isMobile ? 50 : 30"
								:height="$isMobile ? '250px' : '350px'"
								show-legend
							></lee-bar-echarts>
						</div>
						<div class="f16 dark-2">
							<div class="green" :class="$isMobile ? 'f20' : 'f30'">进出口额</div>
<!--							<p v-if="data">-->
<!--								{{ nowYear }}年，进出⼝总额为{{ data.inout.list[data.inout.list.length - 1].total }}亿美⾦，同⽐{{ data.inout.list[data.inout.list.length - 1].rate >= 0 ? '增加' : '减少' }}{{ Math.abs(data.inout.list[data.inout.list.length - 1].rate).toFixed(2) }}%。-->
<!--							</p>-->
							<div class="text-center">
								<lee-bar-echarts
									ref="bar1"
									:show-title="true"
									:show-left="false"
									:bar-width="$isMobile ? 20 : 40"
									title-pos="bottom"
									:title-size="14"
									:title="getTimeType+'中国跨境农产品进出口额'"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="false"></lee-bar-echarts>
							</div>
							<div class="green" :class="$isMobile ? 'f20 margin-top-20' : 'f30'">出口额</div>
							<p v-if="data">
<!--								{{ nowYear }}年，出⼝总额为{{ data.out.now.amount_total.total }}亿美⾦，同⽐{{ data.out.now.amount_total.rate >= 0 ? '增加' : '减少' }}{{ Math.abs(data.out.now.amount_total.rate).toFixed(2) }}%。</p>-->
							<div class="text-center">
								<lee-bar-echarts
									ref="bar2"
									:show-title="true"
									:show-left="false"
									:bar-width="$isMobile ? 20 : 40"
									title-pos="bottom"
									:title-size="14"
									:title="getTimeType+'中国跨境农产品出口额'"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="false"
									:show-right="false"
									:data="[{name:'冷链物流业市场规模',type:'bar',color:'green',data:[3740,4200,4750,5238,6052.5]}]"
									:x-axis="['2015', '2016', '2017', '2018', '2019']"
								></lee-bar-echarts>
							</div>
<!--							<p v-if="data">{{ nowYear }}年，细分品类出口额为：-->
<!--								<template v-for="(item,index) in data.out.now.list">{{ item.title }}{{ item.amount }}亿人民币，</template>-->
<!--								。-->
<!--							</p>-->
							<div class="text-center margin-top-20">
								<lee-bar-echarts
									ref="bar3"
									:title="getTimeType+'中国跨境农产品出口额'"
									:show-right="false"
									:show-left="false"
									:bar-width="$isMobile ? 20 : 40"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="false"
									:data="[{name:'柴油',type:'bar',color:'most',data:[3740,4200,4750,5238,5238,5238]}]"
									:x-axis="['水果', '蔬菜','肉类', '水产品','乳制品', '冷冻食品']"
								></lee-bar-echarts>
							</div>
							<div class="green" :class="$isMobile ? 'f20' : 'f30'">进口额</div>
<!--							<p v-if="data">-->
<!--								{{ nowYear }}年，进⼝总额为{{ data.in.now.amount_total.total }}亿美⾦，同⽐{{ data.in.now.amount_total.rate >= 0 ? '增加' : '减少' }}{{ Math.abs(data.in.now.amount_total.rate).toFixed(2) }}%。</p>-->
							<div class="text-center">
								<lee-bar-echarts
									ref="bar4"
									:show-title="true"
									:show-left="false"
									:bar-width="$isMobile ? 20 : 40"
									title-pos="bottom"
									:title-size="14"
									:title="getTimeType+'中国跨境农产品进口额'"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-right="false"
									:show-legend="false"
									:data="[{name:'冷链物流业市场规模',type:'bar',color:'green',data:[3740,4200,4750,5238,6052.5]}]"
									:x-axis="['2015', '2016', '2017', '2018', '2019']"
								></lee-bar-echarts>
							</div>
<!--							<p v-if="data">{{ nowYear }}年，细分品类进⼝额为：-->
<!--								<template v-for="(item,index) in data.in.now.list">{{ item.title }}{{ item.amount }}亿人民币，</template>-->
<!--								。-->
<!--							</p>-->
							<div class="text-center margin-top-30">
								<lee-bar-echarts
									ref="bar5"
									:show-left="false"
									:bar-width="$isMobile ? 20 : 40"
									:title="getTimeType+'中国跨境农产品进口额'"
									:show-right="false"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-legend="false"
									:data="[{name:'柴油',type:'bar',color:'most',data:[3740,4200,4750,5238,5238,5238]}]"
									:x-axis="['水果', '蔬菜','肉类', '水产品','乳制品', '冷冻食品']"
								></lee-bar-echarts>
							</div>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			yearList: [],
			nowYear: '',

			startYear: '',
			endYear: '',

			data: null,
			yearRange: [],

			time_type:'month'
		}
	},
	created() {
		this.nowYear = this.$route.query.year ? this.$route.query.year : '';
	},
	mounted() {
		// this.getYears();
		this.getTotal1();
	},
	computed:{
		getTimeType(){
			if(this.time_type === 'year'){
				return '年度';
			}else if(this.time_type === 'month'){
				return '月度'
			}else if(this.time_type === 'quarter'){
				return '季度'
			}else if(this.time_type === 'half'){
				return '半年度'
			}
		}
	},
	methods: {
		onChangeTimeType(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataInout/getYears',
				data: null,
				success(res) {
					for (let i = 0; i < res.length; i++) {
						_this.yearList.push({label: res[i] + '年', value: res[i]});
					}

					_this.endYear = res[res.length - 1];

					_this.yearRange = [_this.endYear, res[0]];

					_this.nowYear = _this.nowYear ? _this.nowYear : res[0];

					_this.getTotal1(_this.nowYear);
				}
			});
		},

		getTotal1() {
			//enterprise/statistics
			let _this = this;
			this.$http.post({
				url: '/HydataInout/getData',
				data: {time_type:this.time_type},
				success(res) {
					_this.data = res;

					let _bardata = {bar: [], xaxis: []};
					let _hebing = {bar1: [], bar2: [], xaxis: []};
					let _bardata2 = {bar: [], line1: [],line2: [],xaxis: []};
					let _bardata3 = {bar1: [],bar2:[],bar3:[],bar4:[],bar5:[],bar6:[],bar7:[], xaxis: []};
					let _bardata4 = {bar: [], line1: [],line2: [],xaxis: []};
					let _bardata5 = {bar1: [],bar2:[],bar3:[],bar4:[],bar5:[],bar6:[],bar7:[], xaxis: []};

					for (let i = 0; i < res.inout.list.length; i++) {
						_hebing.bar1.push(res.inout.list[i].in.amount);
						_hebing.bar2.push(res.inout.list[i].out.amount);
						_hebing.xaxis.push(res.inout.list[i].time_str);
					}

					for (let i = 0; i < res.inout.total.length; i++) {
						_bardata.bar.push(res.inout.total[i].total);
						_bardata.xaxis.push(res.inout.total[i].time_str);
					}

					for (let i = 0; i < res.out.list.length; i++) {
						_bardata2.bar.push(res.out.list[i].amount);
						_bardata2.line1.push(res.out.list[i].tongbi);
						_bardata2.line2.push(res.out.list[i].huanbi);
						_bardata2.xaxis.push(res.out.list[i].time_str);
					}

					for (let i = 0; i < res.in.list.length; i++) {
						_bardata4.bar.push(res.in.list[i].amount);
						_bardata4.line1.push(res.in.list[i].tongbi);
						_bardata4.line2.push(res.in.list[i].huanbi);
						_bardata4.xaxis.push(res.in.list[i].time_str);
					}

					for (let i = 0; i < res.out.type.length; i++) {
						_bardata3.bar1.push(res.out.type[i].list.shuiguo ? res.out.type[i].list.shuiguo.amount : 0);
						_bardata3.bar2.push(res.out.type[i].list.shucai ? res.out.type[i].list.shucai.amount : 0);
						_bardata3.bar3.push(res.out.type[i].list.roulei ? res.out.type[i].list.roulei.amount : 0);
						_bardata3.bar4.push(res.out.type[i].list.shuichanpin ? res.out.type[i].list.shuichanpin.amount : 0);
						_bardata3.bar5.push(res.out.type[i].list.sudongshipin ? res.out.type[i].list.sudongshipin.amount : 0);
						_bardata3.bar6.push(res.out.type[i].list.ruzhipin ? res.out.type[i].list.ruzhipin.amount : 0);
						_bardata3.bar7.push(res.out.type[i].list.other ? res.out.type[i].list.other.amount : 0);

						_bardata3.xaxis.push(res.out.type[i].time_str);
					}

					for (let i = 0; i < res.in.type.length; i++) {
						_bardata5.bar1.push(res.in.type[i].list.shuiguo ? res.in.type[i].list.shuiguo.amount : 0);
						_bardata5.bar2.push(res.in.type[i].list.shucai ? res.in.type[i].list.shucai.amount : 0);
						_bardata5.bar3.push(res.in.type[i].list.roulei ? res.in.type[i].list.roulei.amount : 0);
						_bardata5.bar4.push(res.in.type[i].list.shuichanpin ? res.in.type[i].list.shuichanpin.amount : 0);
						_bardata5.bar5.push(res.in.type[i].list.sudongshipin ? res.in.type[i].list.sudongshipin.amount : 0);
						_bardata5.bar6.push(res.in.type[i].list.ruzhipin ? res.in.type[i].list.ruzhipin.amount : 0);
						_bardata5.bar7.push(res.in.type[i].list.other ? res.in.type[i].list.other.amount : 0);

						_bardata5.xaxis.push(res.in.type[i].time_str);
					}

					if (_this.$refs.hebing) {
						_this.$refs.hebing.initData(
							[
								{name: '进口总额', type: 'bar', color: 'green', data: _hebing.bar1, dw: '亿美元'},
								{name: '出口总额', type: 'bar', color: 'blue', data: _hebing.bar2, dw: '亿美元'},
							],
							_hebing.xaxis
						);
					}

					if (_this.$refs.bar1) {
						_this.$refs.bar1.initData(
							[
								{name: '进出口总额', type: 'bar', color: 'green', data: _bardata.bar, dw: '亿美元'},
								// {name: '增长率', type: 'line', color: 'purple', data: _bardata.line, dw: '%'},
							],
							_bardata.xaxis
						);
					}

					if (_this.$refs.bar2) {
						_this.$refs.bar2.initData(
							[
								{name: '出口总额', type: 'bar', color: 'green', data: _bardata2.bar, dw: '亿美元'},
								{name: '同比', type: 'line', color: 'purple', data: _bardata2.line1, dw: '%'},
								{name: '环比', type: 'line', color: 'red', data: _bardata2.line2, dw: '%'},
							],
							_bardata2.xaxis
						);
					}

					if (_this.$refs.bar3) {
						_this.$refs.bar3.initData(
							[
								{name: '水果', type: 'bar', color: 'base', data:_bardata3.bar1, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '蔬菜', type: 'bar', color: 'base', data:_bardata3.bar2, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '肉类', type: 'bar', color: 'base', data:_bardata3.bar3, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '水产品', type: 'bar', color: 'base', data:_bardata3.bar4, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '速冻食品', type: 'bar', color: 'base', data:_bardata3.bar5, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '乳制品', type: 'bar', color: 'base', data:_bardata3.bar6, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '其它', type: 'bar', color: 'base', data:_bardata3.bar7, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
							],
							_bardata3.xaxis
						);
					}

					if (_this.$refs.bar4) {
						_this.$refs.bar4.initData(
							[
								{name: '进口总额', type: 'bar', color: 'green', data: _bardata4.bar, dw: '亿美元'},
								{name: '同比', type: 'line', color: 'purple', data: _bardata4.line1, dw: '%'},
								{name: '环比', type: 'line', color: 'red', data: _bardata4.line2, dw: '%'},
							],
							_bardata4.xaxis
						);
					}

					if (_this.$refs.bar5) {
						_this.$refs.bar5.initData(
							[
								{name: '水果', type: 'bar', color: 'base', data:_bardata5.bar1, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '蔬菜', type: 'bar', color: 'base', data:_bardata5.bar2, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '肉类', type: 'bar', color: 'base', data:_bardata5.bar3, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '水产品', type: 'bar', color: 'base', data:_bardata5.bar4, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '速冻食品', type: 'bar', color: 'base', data:_bardata5.bar5, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '乳制品', type: 'bar', color: 'base', data:_bardata5.bar6, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '其它', type: 'bar', color: 'base', data:_bardata5.bar7, dw: '亿美元',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
							],
							_bardata5.xaxis
						);
					}
				}
			})
		},
	}
}
</script>
