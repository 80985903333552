<template>
	<lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="冷藏车相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷藏车'}]">
		<div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_car">
				<div class="wrap1100">
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<div class="green-line">
							<div class="cl f34">
								<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
								<div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">冷藏车相关数据</div>
								<div class="y">
									<lee-type-select v-model="time_type" :options="[{id:'month',name:'月度'},{id:'quarter',name:'季度'},{id:'half',name:'半年度'},{id:'year',name:'年度'}]" :can-add="false" @change="onChangeTimeType"></lee-type-select>
								</div>
							</div>
						</div>
						<div class="f16 dark-2">
							<div class="text-center">
								<lee-bar-echarts
									ref="bar1"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									show-legend
								></lee-bar-echarts>
							</div>
							<div class="text-center">
								<el-row>
									<el-col :md="10" :xs="24">
										<lee-pie-echarts
											ref="pie1"
											:grid-bottom="$isMobile ? 100 : 30"
											:height="$isMobile ? '300px' : '350px'"
											:show-legend="false"
											label-format="{b}:{d}%"
											label-pos="outside"
											:radius="['30%','50%']"></lee-pie-echarts>
									</el-col>
									<el-col :md="14" :xs="24">
										<lee-bar-echarts-hor
											ref="bar2"
											:grid-bottom="$isMobile ? 50 : 30"
											:height="$isMobile ? '250px' : '350px'"
											show-legend
											:legend-orient="$isMobile ? 'horizontal' : 'vertical'"
											:legend-pos="$isMobile ? 'bottom' : 'right'"
											:show-left="true"
											:show-right="false"
										></lee-bar-echarts-hor>
									</el-col>
								</el-row>
							</div>
							<div class="text-center">
								<lee-bar-echarts
									ref="bar3"
									:show-left="false"
									:show-right="false"
									:grid-bottom="30"
									height="350px"
									:show-legend="false"
								></lee-bar-echarts>
							</div>
							<div class="text-center">
								<lee-bar-echarts
									ref="bar4"
									:grid-bottom="$isMobile ? 50 : 30"
									:height="$isMobile ? '250px' : '350px'"
									:show-left="false"
									:show-right="false"
									:show-legend="false"
									:data="[{name:'柴油',type:'bar',color:'green',data:[3740,4200,4750,5238]}]"
									:x-axis="['柴油', '汽油', '天然气', '电动']"
								></lee-bar-echarts>
							</div>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			yearList: [],
			nowYear: '',

			startYear: '',
			endYear: '',

			data: null,
			bardata: null,

			areaSort: [],
			time_type:'month'
		}
	},
	created() {
		let _this = this;
	},
	mounted() {
		this.getTotal1();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCar/getYears',
				data: null,
				success(res) {
					for (let i = 0; i < res.length; i++) {
						_this.yearList.push({label: res[i] + '年', value: res[i]});
					}

					_this.nowYear = _this.nowYear ? _this.nowYear : res[0];

					_this.endYear = res[res.length - 1];

					_this.getTotal1(_this.nowYear);
				}
			});
		},
		onChangeTimeType(e){
			this.getTotal1();
		},
		getTotal1() {
			let _this = this;
			this.$http.post({
				url: '/HydataColdCar/getData',
				data: {time_type:this.time_type},
				success(res) {
					_this.data = res;

					let _bardata1 = {bar: [], line1: [],line2: [], xaxis: []};
					let _bardata3 = {bar1: [],bar2:[],bar3:[],bar4:[],line1: [],line2: [], xaxis: []};
					let _bardata4 = {bar1: [],bar2:[],bar3:[],bar4:[],line1: [],line2: [], xaxis: []};
					let _bar2data = {bar:[],bar2:[],xaxis:[]};
					let _pie1Data = [];

					let _last_area = res.area.list[res.area.list.length - 1];
					let _pre_area = res.area.list[res.area.list.length - 2];

					_pie1Data.push({value:_last_area.huazhong,name:'华中'});
					_pie1Data.push({value:_last_area.huadong,name:'华东'});
					_pie1Data.push({value:_last_area.huanan,name:'华南'});
					_pie1Data.push({value:_last_area.huabei,name:'华北'});
					_pie1Data.push({value:_last_area.dongbei,name:'东北'});
					_pie1Data.push({value:_last_area.xinan,name:'西南'});
					_pie1Data.push({value:_last_area.xibei,name:'西北'});

					_bar2data.bar.push(_last_area.huazhong);
					_bar2data.bar.push(_last_area.huadong);
					_bar2data.bar.push(_last_area.huanan);
					_bar2data.bar.push(_last_area.huabei);
					_bar2data.bar.push(_last_area.dongbei);
					_bar2data.bar.push(_last_area.xinan);
					_bar2data.bar.push(_last_area.xibei);

					_bar2data.bar2.push(_pre_area.huazhong);
					_bar2data.bar2.push(_pre_area.huadong);
					_bar2data.bar2.push(_pre_area.huanan);
					_bar2data.bar2.push(_pre_area.huabei);
					_bar2data.bar2.push(_pre_area.dongbei);
					_bar2data.bar2.push(_pre_area.xinan);
					_bar2data.bar2.push(_pre_area.xibei);

					for (let i = 0; i < res.count.list.length; i++) {
						_bardata1.bar.push(res.count.list[i].total);
						_bardata1.line1.push(res.count.list[i].tongbi);
						_bardata1.line2.push(res.count.list[i].huanbi);
						_bardata1.xaxis.push(res.count.list[i].time_str);
					}

					for (let i = 0; i < res.type.list.length; i++) {
						_bardata3.bar1.push(res.type.list[i] ? res.type.list[i].heavy : 0);
						_bardata3.bar2.push(res.type.list[i] ? res.type.list[i].small : 0);
						_bardata3.bar3.push(res.type.list[i] ? res.type.list[i].light : 0);
						_bardata3.bar4.push(res.type.list[i] ? res.type.list[i].mini : 0);

						_bardata3.line1.push(res.type.list[i].tongbi);
						_bardata3.line2.push(res.type.list[i].huanbi);

						_bardata3.xaxis.push(res.type.list[i].time_str);
					}

					for (let i = 0; i < res.oil.list.length; i++) {
						_bardata4.bar1.push(res.oil.list[i] ? res.oil.list[i].chaiyou : 0);
						_bardata4.bar2.push(res.oil.list[i] ? res.oil.list[i].qiyou : 0);
						_bardata4.bar3.push(res.oil.list[i] ? res.oil.list[i].tianranqi : 0);
						_bardata4.bar4.push(res.oil.list[i] ? res.oil.list[i].diandong : 0);

						_bardata4.line1.push(res.oil.list[i].tongbi);
						_bardata4.line2.push(res.oil.list[i].huanbi);

						_bardata4.xaxis.push(res.oil.list[i].time_str);
					}

					if(_this.$refs.bar1) {
						_this.$refs.bar1.initData(
							[
								{name: '保有量', type: 'bar', color: 'green', data: _bardata1.bar,dw:'辆'},
								{name: '同比', type: 'line', color: 'purple', data: _bardata1.line1,dw:'%'},
								{name: '环比', type: 'line', color: 'red', data: _bardata1.line2,dw:'%'},
							],
							_bardata1.xaxis
						);
					}

					if(_this.$refs.pie1) {
						_this.$refs.pie1.initData(_pie1Data);
					}

					if(_this.$refs.bar2) {
						_this.$refs.bar2.initData(
							[
								{name: _last_area.time_str, type: 'bar', color: 'green', data: _bar2data.bar,dw:'辆'},
								{name: _pre_area.time_str, type: 'bar', color: 'blue', data: _bar2data.bar2,dw:'辆'},
							],
							['华中','华东','华南','华北','东北','西南','西北']
						);
					}

					if (_this.$refs.bar3) {
						_this.$refs.bar3.initData(
							[
								{name: '同比', type: 'line', color: 'purple', data: _bardata3.line1,dw:'%'},
								{name: '环比', type: 'line', color: 'red', data: _bardata3.line2,dw:'%'},
								{name: '重型', type: 'bar', color: 'base', data:_bardata3.bar1, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '中型', type: 'bar', color: 'base', data:_bardata3.bar2, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '轻型', type: 'bar', color: 'base', data:_bardata3.bar3, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '微型', type: 'bar', color: 'base', data:_bardata3.bar4, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
							],
							_bardata3.xaxis
						);
					}

					if (_this.$refs.bar4) {
						_this.$refs.bar4.initData(
							[
								{name: '同比', type: 'line', color: 'purple', data: _bardata4.line1,dw:'%'},
								{name: '环比', type: 'line', color: 'red', data: _bardata4.line2,dw:'%'},
								{name: '柴油', type: 'bar', color: 'base', data:_bardata4.bar1, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '汽油', type: 'bar', color: 'base', data:_bardata4.bar2, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '天然气', type: 'bar', color: 'base', data:_bardata4.bar3, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
								{name: '电动', type: 'bar', color: 'base', data:_bardata4.bar4, dw: '辆',
									options: {
										stack: 'total',
										label: {
											show: true
										},
										emphasis: {
											focus: 'series'
										}
									}
								},
							],
							_bardata4.xaxis
						);
					}
				}
			})
		},
	}
}
</script>
