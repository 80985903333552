<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '120' : '280'" :back="$isMobile" :title="$isMobile ? '' : info.title" :bread="[{title:'企业数据库',url:'/company'},{title:info.title}]" v-if="info">
        <div :class="!$isMobile ? 'bg-img-2 padding-bottom-400 padding-top-100' : 'padding-left-right'" :style="{backgroundColor: !$isMobile ? '#f8f9fa' : '',marginTop:$isMobile ? '-40px' : ''}">
            <div :class="$isMobile ? '' : 'wrap1100'">
                <div class="box-shadow bg-light" :class="$isMobile ? 'padding border-radius-5' : 'padding-30'" v-if="info">
                    <div class="green-line">
                        <div class="cl f34" style="line-height: 30px;">
                            <span class="z line-index bg-green light f16">{{info.arank}}</span>
                            <div class="z margin-left" :class="$isMobile ? 'f18' : 'f22'">{{info.title}}</div>
                            <lee-updown-tag class="y" :type="info.arank_change > 0 ? 'up' : 'down'" :number="info.arank_change" :isnew="info.arank_change === 0" v-if="!$isMobile"></lee-updown-tag>
                        </div>
                    </div>
                    <div class="f16 dark-1">
                        <img src="/images/banner_1.jpg" class="content-img"/>
<!--                        <p>10月16日，富士康母公司鸿海集团表示，计划在2025-2027年占据全球电动汽车市场10%份额。“我们希望将台湾的电动汽车产业推向世界。” 鸿海董事长刘扬伟表示，但集团并不会打造自己的电动汽车品牌。 鸿海集团将通过建立“开放平台”的方式，向汽车制造商生产包含电池在内的电动汽车零部件，以及提供车联网服务。刘扬伟表示与车企的谈判已取得“相对良好”的进展。</p>-->
<!--                        <p>同日，鸿海集团宣布推出其第一个电动汽车底盘和一个软件平台，旨在帮助电动汽车制造商更快地交付车型。到2024年，鸿海集团将推出一款能够改进现有电池的大容量储存设备。除了新能源和车联网，鸿海集团在自动驾驶领域的布局也已做出一定成绩。鸿海集团高管表示，其自动驾驶技术已经在日本的一些机场班车中有所应用。-->
<!--                            作为苹果公司的主要供应商，富士康的野心并没有囿于手机代工，早已将目光投向汽车业。-->
<!--                        </p>-->
                        <div class="margin-top box-shadow padding-20" v-if="$user.getToken()">
                            <el-row :gutter="10">
                                <el-col :md="3" :xs="8" class="violet f16">省份</el-col>
                                <el-col :md="9" :xs="16" class="f-thin f16">{{info.province_name}}</el-col>
<!--                                <el-col :md="3" :xs="8" class="violet f16" :class="$isMobile ? 'margin-top-5' : ''">区域</el-col>-->
<!--                                <el-col :md="9" :xs="16" class="f-thin f16" :class="$isMobile ? 'margin-top-5' : ''">{{info.regional.name}}</el-col>-->
                            </el-row>
                            <el-row :gutter="10" :class="$isMobile ? 'margin-top-5' : 'margin-top-20'">
                                <el-col :md="3" :xs="8" class="violet f16">营收</el-col>
<!--                                <el-col :md="9" :xs="16" class="f-thin f16">{{info.amount ? info.amount+'万元' : '-'}}</el-col>-->
	                            <el-col :md="9" :xs="16" class="f-thin f16">-</el-col>
                                <el-col :md="3" :xs="8" class="violet f16" :class="$isMobile ? 'margin-top-5' : ''">入围门槛</el-col>
                                <el-col :md="9" :xs="16" class="f-thin f16" :class="$isMobile ? 'margin-top-5' : ''">{{menkan}}万元</el-col>
                            </el-row>
                            <el-row :gutter="10" :class="$isMobile ? 'margin-top-5' : 'margin-top-20'">
                                <el-col :md="3" :xs="8" class="violet f16">企业性质</el-col>
                                <el-col :md="9" :xs="16" class="f-thin f16">{{info.nature.name ? info.nature.name : '-'}}</el-col>
                                <el-col :md="3" :xs="8" class="violet f16" :class="$isMobile ? 'margin-top-5' : ''">主营业务</el-col>
                                <el-col :md="9" :xs="16" class="f-thin f16" :class="$isMobile ? 'margin-top-5' : ''">
                                    <el-tag type="success" effect="dark" size="mini" v-if="info.is_peisong === 1" class="margin-right">配送型业务</el-tag>
                                    <el-tag type="success" effect="dark" size="mini" v-if="info.is_baoshui === 1" class="margin-right">保税型业务</el-tag>
                                    <el-tag type="success" effect="dark" size="mini" v-if="info.is_yuanqu === 1" class="margin-right">园区型业务</el-tag>
                                    <el-tag type="success" effect="dark" size="mini" v-if="info.is_pifa === 1">批发市场型业务</el-tag>
                                </el-col>
                            </el-row>
<!--                            <el-row :gutter="10" :class="$isMobile ? 'margin-top-5' : 'margin-top-20'">-->
<!--                                <el-col :md="3" :xs="8" class="violet f16">人员规模</el-col>-->
<!--                                <el-col :md="9" :xs="16" class="f-thin f16">{{info.staff_number}}人</el-col>-->
<!--                                <el-col :md="3" :xs="8" class="violet f16" :class="$isMobile ? 'margin-top-5' : '-'">企业业务</el-col>-->
<!--                                <el-col :md="9" :xs="16" class="f-thin f16" :class="$isMobile ? 'margin-top-5' : '-'">{{info.bussiness_type ? info.bussiness_type.name : ''}}</el-col>-->
<!--                            </el-row>-->
                            <el-row :gutter="10" :class="$isMobile ? 'margin-top-5' : 'margin-top-20'">
                                <el-col :md="3" :xs="8" class="violet f16">冷藏车总量</el-col>
                                <el-col :md="9" :xs="16" class="f-thin f16">{{info.mini_car + info.light_car + info.small_car + info.heavy_car + info.out_car + info.owner_car}}辆</el-col>
                                <el-col :md="3" :xs="8" class="violet f16" :class="$isMobile ? 'margin-top-5' : ''">冷库总容积</el-col>
                                <el-col :md="9" :xs="16" class="f-thin f16" :class="$isMobile ? 'margin-top-5' : ''">{{info.lengku_rl ? parseFloat(info.lengku_rl) : 0}}立方米</el-col>
                            </el-row>

                            <div class="text-center margin-top-30">
                                <lee-bar-echarts
                                    ref="bar1"
                                    :grid-bottom="30"
                                    height="350px"
                                    show-legend
                                ></lee-bar-echarts>
                            </div>
                        </div>
                      <div class="box-shadow bg-light green padding-20 text-center border-radius-5 margin-auto margin-top-70 margin-bottom-30" style="width: 500px;" v-else>
                        <i class="orange margin-right el-icon-warning"></i>你无权查看该信息，请登录<login-button></login-button>
                      </div>
                    </div>
                    <lee-good-view class="margin-top-50" :id="info.id" akey="company"></lee-good-view>
                    <lee-comment-view :id="info.id" akey="company"></lee-comment-view>
                </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            textarea:'',
            id:'',
            info:null,
            menkan:0,
            list:[]
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
        this.getMenKan();
        this.getData();
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.post({
                url: '/Baiqiang/detail',
                data: {id:this.id},
                success(res) {
                    _this.info = res;

                    _this.getMenKan(res.year);
                }
            });
        },
        getMenKan(year){
            let _this = this;
            this.$http.post({
                url: '/BaiqiangTotal/detail',
                data: {year:year},
                success(res) {
                    _this.menkan = res.menkan;
                }
            });
        },
        getData(){
            let _this = this;
            this.$http.post({
                url: '/Baiqiang/getAllYear',
                data: {id:this.id},
                success(res) {
                    _this.list = res;

                    let _bar = {bar1:[],bar2:[],bar3:[],bar4:[],xaxis:[]};

                    console.log(res);

                    for(let i=0;i < res.length;i++) {

                        _bar.bar1.push(res[i].amount);

                        let _lengku = res[i].lengku_rl ? parseFloat(res[i].lengku_rl) : 0;

                        // _lengku += parseFloat(res[i].changwenku);
                        // _lengku += parseFloat(res[i].chaodiwenku);
                        // _lengku += parseFloat(res[i].lengcangku);
                        // _lengku += parseFloat(res[i].lengdongku);

                        let _car = 0;
                        _car += res[i].heavy_car;
                        _car += res[i].light_car;
                        _car += res[i].mini_car;
                        _car += res[i].out_car;
                        _car += res[i].small_car;
                        _car += res[i].owner_car;

                        _bar.bar2.push(_lengku);
                        _bar.bar3.push(_car);
                        _bar.bar4.push(res[i].person_count);
                        _bar.xaxis.push(res[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                // {name: '营业收入', type: 'bar', color: 'green', data: _bar.bar1,dw:'万元'},
                                {name: '冷库总容积', type: 'bar', color: 'blue', data: _bar.bar2,dw:'立方米'},
                                {name: '冷藏车总量', type: 'bar', color: 'orange', data: _bar.bar3,dw:'辆'},
                                {name: '企业人数', type: 'bar', color: 'red', data: _bar.bar4,dw:'人'},
                            ],
                            _bar.xaxis
                        );
                    }
                }
            });
        },
    }
}
</script>
