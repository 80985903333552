<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="产量相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'产量'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_harvest">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">产量相关数据</div>
                            </div>
<!--                            <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">-->
<!--                                <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
<!--                                <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
<!--                                <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
<!--                            </el-row>-->
                        </div>
                        <div class="f16 dark-2">
                            <p v-if="pieData">{{nowYear}}年，<template v-for="(item,index) in pieData">{{item.name}}总产量为{{item.value}}万吨{{index + 1 === pieData.length ? '。' : '，'}}</template></p>
                            <div class="margin-auto" style="width:600px">
                                <lee-pie-echarts
                                    ref="pie1"
                                    :grid-bottom="30"
                                    height="350px"
                                    :label-format="'{b}\n{d}%'"
                                    label-pos="outside"
                                    :legend-orient="$isMobile ? 'horizontal' : 'vertical'"
                                    :legend-pos="$isMobile ? 'bottom' : 'right'"
                                    :radius="['0%','60%']"></lee-pie-echarts>
                            </div>
                            <template v-for="(item,index) in tabList" v-if="data">
                                <lee-hash-tab :id="'a'+(index+1)" class="margin-top-50" :list="tabList" :active-index="index"></lee-hash-tab>
                              <template v-if="item.val === 'sudongshipin'">
                                <p v-if="data && data[item.val]">
                                  据统计数据显示,{{nowYear}}年全国{{item.title}}市场规模约{{data[item.val].now.total}}亿元，同比{{data[item.val].now.rate >= 0 ? '增长' : '减少'}}{{Math.abs(data[item.val].now.rate)}}%。 <template v-if="data.lenglian && data.lenglian.now && data.lenglian.now[item.val]">根据{{nowYear}}年我国{{item.title}}和冷链流通率测算，{{nowYear}}我国速冻食品冷链需求量为{{data.lenglian.now[item.val]}}万吨</template>
                                </p>
<!--                                <p v-if="data && data[item.val]">据统计数据显示,{{nowYear}}年全国{{item.title}}总产量约{{data[item.val].now.total}}亿吨，同比{{data[item.val].now.rate >= 0 ? '增长' : '减少'}}{{Math.abs(data[item.val].now.rate)}}%。-->
<!--                                  <template v-if="data.lenglian && data.lenglian.now && data.lenglian.now[item.val]">根据{{nowYear}}年我国{{item.title}}和冷链流通率测算，{{nowYear}}我国{{item.title}}冷链需求量为{{data.lenglian.now[item.val]}}万吨</template></p>-->
                                <lee-bar-echarts
                                  v-if="data[item.val]"
                                  ref="bar"
                                  :grid-bottom="$isMobile ? 50 : 30"
                                  :height="$isMobile ? '250px' : '350px'"
                                  :show-legend="false"
                                  :show-left="false"
                                  :show-right="false"
                                  :data="[formatBar(data[item.val].list,'市场规模(亿元)')]"
                                  :x-axis="formatX(data[item.val].list)"
                                ></lee-bar-echarts>
                              </template>
                              <template v-else>
                                <p v-if="data && data[item.val]">据统计数据显示,{{nowYear}}年全国{{item.title}}总产量约{{data[item.val].now.total}}亿吨，同比{{data[item.val].now.rate >= 0 ? '增长' : '减少'}}{{Math.abs(data[item.val].now.rate)}}%。
                                    <template v-if="data.lenglian && data.lenglian.now && data.lenglian.now[item.val]">根据{{nowYear}}年我国{{item.title}}和冷链流通率测算，{{nowYear}}我国{{item.title}}冷链需求量为{{data.lenglian.now[item.val]}}万吨</template></p>
                                <lee-bar-echarts
                                    v-if="data[item.val]"
                                    ref="bar"
                                    :grid-bottom="$isMobile ? 50 : 30"
                                    :height="$isMobile ? '250px' : '350px'"
                                    :show-legend="false"
                                    :show-left="false"
                                    :show-right="false"
                                    :data="[formatBar(data[item.val].list)]"
                                    :x-axis="formatX(data[item.val].list)"
                                ></lee-bar-echarts>
                                <table class="lee-table margin-auto" style="width:100%;" v-if="hasTable.indexOf(item.val) >= 0">
                                    <thead>
                                    <td width="200" class="f14">各省份{{item.title}}产量(万吨)</td>
                                    <td v-for="(itemhd,indexhd) in data[item.val].list">{{itemhd.year}}年</td>
                                    </thead>
                                    <tr v-for="(aitem,aindex) in areaList">
                                        <td>{{aitem.name}}</td>
                                        <td v-for="(itembd,indexbd) in data[item.val].list">{{parseFloat(itembd[aitem.value])}}</td>
                                    </tr>
                                </table>
                              </template>
                            </template>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
            pieData:null,
            hasTable:['shuiguo','shucai','rouzhipin','shuichanpin'],
            tabList:[
                {title:'水果',link:'#a1',val:'shuiguo'},
                {title:'蔬菜',link:'#a2',val:'shucai'},
                {title:'肉类',link:'#a3',val:'rouzhipin'},
                {title:'水产品',link:'#a4',val:'shuichanpin'},
                {title:'乳制品',link:'#a5',val:'ruzhipin'},
                {title:'速冻食品',link:'#a6',val:'sudongshipin'}
            ],
            areaList:[
                {name:'北京',value:'beijing'},
                {name:'天津',value:'tianjing'},
                {name:'河北',value:'hebei'},
                {name:'山东',value:'shandong'},
                {name:'江苏',value:'jiangsu'},
                {name:'浙江',value:'zhejiang'},
                {name:'上海',value:'shanghai'},
                {name:'广东',value:'guangdong'},
                {name:'福建',value:'fujian'},
                {name:'海南',value:'hainan'},
                {name:'河南',value:'henan'},
                {name:'安徽',value:'anhui'},
                {name:'湖北',value:'hubei'},
                {name:'湖南',value:'hunan'},
                {name:'江西',value:'jiangxi'},
                {name:'黑龙江',value:'heilongjiang'},
                {name:'吉林',value:'jilin'},
                {name:'辽宁',value:'liaoning'},
                {name:'新疆',value:'xinjiang'},
                {name:'甘肃',value:'gansu'},
                {name:'宁夏',value:'ningxia'},
                {name:'陕西',value:'shanxi'},
                {name:'青海',value:'qinghai'},
                {name:'西藏',value:'xizang'},
                {name:'山西',value:'shanxi2'},
                {name:'四川',value:'sichuan'},
                {name:'重庆',value:'chongqing'},
                {name:'贵州',value:'guizhou'},
                {name:'云南',value:'yunnan'},
                {name:'广西',value:'guangxi'},
                {name:'蒙古',value:'menggu'},
            ]
        }
    },
    created() {
        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
        // this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;

    },
    mounted() {
        this.getYears();
    },
    methods:{
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataChanliang/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];
                    _this.endYear = res[res.length - 1];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year) {
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url: '/HydataChanliang/getData',
                data: {year: year,yearlist:[year,year - 4]},
                success(res) {
                    _this.data = res;

                    let _pie1data = [];
                    _pie1data.push({value:res.shuiguo.now.total,name:'水果'});
                    _pie1data.push({value:res.shucai.now.total,name:'蔬菜'});
                    _pie1data.push({value:res.rouzhipin.now.total,name:'肉制品'});
                    _pie1data.push({value:res.shuichanpin.now.total,name:'水产品'});
                    _pie1data.push({value:res.ruzhipin.now.total,name:'乳制品'});
                    _pie1data.push({value:res.sudongshipin.now.total,name:'速冻食品'});

                    _this.pieData = _pie1data;

                    console.log(_this.$refs.pie1);

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1data);
                    }
                }
            });
        },

        formatBar(data,dw = '产量(万吨)'){
            let _bardata = {bar:[]};

            for(let i=0;i < data.length;i++) {
                _bardata.bar.push(data[i].total);
            }

            return {name: dw, type: 'bar', color: 'green', data:_bardata.bar};
        },

        formatX(data){
            let _bardata = {xaxis:[]};

            for(let i=0;i < data.length;i++) {
                _bardata.xaxis.push(data[i].year.toString());
            }

            return _bardata.xaxis;
        },
    }
}
</script>
